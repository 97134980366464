import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import ConfigView from '../pages/ConfigView';
import AllyView from '../pages/AllyView';
import ErrorPage from '../pages/ErrorPage';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/config" element={<Home />} />
        <Route path="/config/:id" element={<ConfigView />} />
        <Route path="/contrast" element={<AllyView />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/auth/callback" element={<div>Redirecting...</div>} />
      </Routes>
    </Router>
  );
}

export default App;