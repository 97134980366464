import React, { useState, useRef, useEffect } from 'react';
import { Cog, User, LogOut } from 'lucide-react';
import SettingsView from "../pages/SettingsView";
import AlertView from "../pages/AlertView";
import Avatar from "./ui/Avatar";
import { useAuth } from "../hooks/useAuth";
import { Auth } from "./Auth";
import { supabase } from '../lib/supabase';

const Layout = ({ children }) => {
  const { user, loading } = useAuth();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [authOpen, setAuthOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const userMenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSettingsOpen = () => {
    setSettingsOpen(true);
  };

  const handleAuthOpen = () => {
    setAuthOpen(true);
  };

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    setUserMenuOpen(false);
  };

  // Get user's initials if available
  const getUserInitials = () => {
    if (!user) return null;
    if (user.user_metadata?.full_name) {
      return user.user_metadata.full_name
        .split(' ')
        .map(name => name[0])
        .join('')
        .toUpperCase();
    }
    if (user.email) {
      return user.email[0].toUpperCase();
    }
    return 'U';
  };

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="animate-spin h-8 w-8 border-4 border-blue-500 border-t-transparent rounded-full" />
      </div>
    );
  }

  return (
    <main>
      {settingsOpen && <SettingsView setSettingsOpen={setSettingsOpen} />}
      {alertOpen && <AlertView setAlertOpen={setAlertOpen} />}
      {authOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl max-w-sm w-full">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold">Sign In</h2>
              <button 
                onClick={() => setAuthOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
            </div>
            <Auth onSuccess={() => setAuthOpen(false)} />
          </div>
        </div>
      )}
      
      <header className="flex flex-row gap-2 mx-16 mb-6 justify-between mt-8">
        <div>
          <img
            src="/logo.png"
            alt="astral logo dark"
            width="100px"
            className="dark:block hidden"
          />
          <img
            src="/logo_black.png"
            alt="astral logo light"
            width="100px"
            className="dark:hidden block"
          />
        </div>

        <div className="flex flex-row gap-5 items-center text-sm">
          <button 
            onClick={handleSettingsOpen} 
            className="flex flex-row gap-1 items-center hover:opacity-75"
          >
            <Cog size={16} />
            Settings
          </button>
          
          {user ? (
            <div className="relative" ref={userMenuRef}>
              <button
                onClick={() => setUserMenuOpen(!userMenuOpen)}
                className="hover:opacity-75"
              >
                <Avatar initial={getUserInitials()} />
              </button>
              
              {userMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 py-1 z-50">
                  <div className="px-4 py-2 border-b border-gray-200 dark:border-gray-700">
                    <div className="font-medium truncate">{user.email}</div>
                  </div>
                  <button
                    onClick={handleSignOut}
                    className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-50 dark:hover:bg-gray-700 flex items-center gap-2"
                  >
                    <LogOut size={16} />
                    Sign Out
                  </button>
                </div>
              )}
            </div>
          ) : (
            <button 
              onClick={handleAuthOpen}
              className="flex items-center gap-2 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-800"
            >
              <User size={16} />
              Sign In
            </button>
          )}
        </div>
      </header>

      <div className="mx-12">
        {children}
      </div>

      <footer className="flex flex-col items-center p-8 text-sm gap-1">
        <p className="dark:text-slate-500 text-center">
          Copyright &copy; 2024 nickmjones/getastral.app • All rights reserved, all wrongs reversed
        </p>
        <p className="text-center">
          Made with <span className="text-red-600 mx-1">♥</span> in NYC
        </p>
      </footer>
    </main>
  );
};

export default Layout;