import React from 'react';
import { ChevronDown } from 'lucide-react';

const ColorRoleEditor = ({ color, onRoleChange, disabled = false }) => {
  const roles = [
    { id: 'none', label: 'None' },
    { id: 'primary', label: 'Primary' },
    { id: 'secondary', label: 'Secondary' },
    { id: 'tertiary', label: 'Tertiary' },
    { id: 'success', label: 'Success' },
    { id: 'warning', label: 'Warning' },
    { id: 'error', label: 'Error' },
    { id: 'info', label: 'Info' },
    { id: 'surface', label: 'Surface' },
    { id: 'background', label: 'Background' }
  ];

  return (
    <div className="relative">
      <p className="text-sm mb-1 text-black/50 dark:text-white/50">Color Role</p>
      <select
        value={color.role || 'none'}
        onChange={(e) => onRoleChange(e.target.value)}
        disabled={disabled}
        className="w-full p-2 rounded text-sm focus:outline-none focus:ring focus:ring-blue-500 appearance-none pr-8 input-border bg-[hsl(var(--surface))] disabled:opacity-50"
      >
        {roles.map(role => (
          <option key={role.id} value={role.id}>
            {role.label}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none text-gray-500">
        <ChevronDown size={16} />
      </div>
    </div>
  );
};

export default ColorRoleEditor;