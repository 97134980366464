import chroma from 'chroma-js';
import colorNamer from 'color-namer';

export const normalizeHex = (hex) => hex?.startsWith('#') ? hex : `#${hex}`;

export const typeScales = {
  'minor-second': 1.067,
  'major-second': 1.125,
  'minor-third': 1.2,
  'major-third': 1.25,
  'perfect-fourth': 1.333,
  'augmented-fourth': 1.414,
  'perfect-fifth': 1.5
};

export const headingSizes = {
  'text-xl': 1.25,
  'text-2xl': 1.5,
  'text-3xl': 1.875,
  'text-4xl': 2.25,
  'text-5xl': 3,
  'text-6xl': 3.75
};

export const leadingOptions = {
  'leading-none': '1',
  'leading-tight': '1.25',
  'leading-snug': '1.375',
  'leading-normal': '1.5',
  'leading-relaxed': '1.625',
  'leading-loose': '2'
};

export const popularFonts = [
  'Inter',
  'Roboto',
  'Open Sans',
  'Lato',
  'Montserrat',
  'Poppins',
  'Source Sans Pro',
  'Raleway',
  'Ubuntu',
  'Playfair Display',
  'Merriweather',
  'Work Sans',
  'DM Sans',
  'Space Grotesk',
  'IBM Plex Sans'
];

export const ColorRole = {
  NONE: 'none',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary'
};

export const PALETTE_STRATEGIES = {
  ANALOGOUS: 'Analogous',
  TRIADIC: 'Triadic',
  SPLIT_COMPLEMENTARY: 'Split Complementary',
  MONOCHROMATIC_ACCENT: 'Monochromatic Accent'
};

const DEFAULT_GRAY_SCALE = {
  100: '#f8f9fa',
  200: '#e9ecef',
  300: '#dee2e6',
  400: '#ced4da',
  500: '#adb5bd',
  600: '#6c757d',
  700: '#495057',
  800: '#343a40',
  900: '#212529'
};

export function generateColorScale(baseColor) {
  try {
    if (!baseColor || typeof baseColor !== 'string') {
      console.error('Invalid color input:', baseColor);
      return DEFAULT_GRAY_SCALE;
    }

    const cleanBaseColor = baseColor.startsWith('#') ? baseColor : `#${baseColor}`;

    const scale = {};
    const colors = [];

    // Generate lighter variants (100-500)
    for (let i = 5; i >= 1; i--) {
      const mixRatio = 1 - (i / 6);
      const color = chroma.mix('#ffffff', cleanBaseColor, mixRatio, 'lab').hex();
      colors.push({ step: i * 100, color });
    }

    // Add base color at 600
    colors.push({ step: 600, color: cleanBaseColor });

    // Generate darker variants (700-900)
    for (let i = 1; i <= 3; i++) {
      const mixRatio = i / 4;
      const color = chroma.mix(cleanBaseColor, '#000000', mixRatio, 'lab').hex();
      colors.push({ step: 600 + (i * 100), color });
    }

    // Sort colors by luminance to ensure proper light-to-dark progression
    colors.sort((a, b) => {
      const lumA = chroma(a.color).luminance();
      const lumB = chroma(b.color).luminance();
      return lumB - lumA;
    });

    // Reassign steps based on sorted luminance
    colors.forEach((color, index) => {
      const step = (index + 1) * 100;
      scale[step] = color.color;
    });

    // Verify the scale is properly ordered
    const luminances = Object.values(scale).map(color => chroma(color).luminance());
    const isOrdered = luminances.every((lum, i) => i === 0 || lum <= luminances[i - 1]);

    if (!isOrdered) {
      console.warn('Color scale not properly ordered, falling back to default');
      return DEFAULT_GRAY_SCALE;
    }

    return scale;
  } catch (error) {
    console.error('Error generating color scale:', error);
    return DEFAULT_GRAY_SCALE;
  }
}

function generatePaletteColors(strategy = PALETTE_STRATEGIES.ANALOGOUS) {
  const baseHue = Math.random() * 360;
  const baseSat = 0.55 + (Math.random() * 0.2);
  const baseLightness = 0.45 + (Math.random() * 0.15);
  const baseColor = chroma.hsl(baseHue, baseSat, baseLightness);

  switch (strategy) {
    case PALETTE_STRATEGIES.ANALOGOUS:
      return [
        baseColor.hex(),
        chroma.hsl((baseHue + 30) % 360, baseSat * 0.9, baseLightness * 1.1).hex(),
        chroma.hsl((baseHue + 60) % 360, baseSat * 0.8, baseLightness * 0.9).hex(),
        chroma.hsl((baseHue - 30) % 360, baseSat * 0.7, baseLightness * 1.2).hex(),
        chroma.hsl((baseHue - 60) % 360, baseSat * 0.6, baseLightness).hex(),
        chroma.hsl((baseHue + 90) % 360, baseSat * 0.75, baseLightness * 1.05).hex(), // Added 90° shift for sixth color
      ];

    case PALETTE_STRATEGIES.TRIADIC:
      return [
        baseColor.hex(),
        chroma.hsl((baseHue + 120) % 360, baseSat, baseLightness * 1.1).hex(),
        chroma.hsl((baseHue + 240) % 360, baseSat * 0.9, baseLightness * 0.9).hex(),
        chroma.hsl((baseHue + 60) % 360, baseSat * 0.7, baseLightness * 1.2).hex(),
        chroma.hsl((baseHue + 180) % 360, baseSat * 0.8, baseLightness).hex(),
        chroma.hsl((baseHue + 300) % 360, baseSat * 0.85, baseLightness * 1.15).hex(), // Added intermediate hue
      ];

    case PALETTE_STRATEGIES.SPLIT_COMPLEMENTARY:
      return [
        baseColor.hex(),
        chroma.hsl((baseHue + 150) % 360, baseSat * 0.9, baseLightness * 1.1).hex(),
        chroma.hsl((baseHue + 210) % 360, baseSat * 0.8, baseLightness * 0.9).hex(),
        chroma.hsl((baseHue + 30) % 360, baseSat * 0.7, baseLightness * 1.2).hex(),
        chroma.hsl(baseHue, baseSat * 0.5, baseLightness * 1.3).hex(),
        chroma.hsl((baseHue + 180) % 360, baseSat * 0.75, baseLightness * 0.95).hex(), // Added true complement
      ];

    case PALETTE_STRATEGIES.MONOCHROMATIC_ACCENT:
      return [
        baseColor.hex(),
        chroma.hsl(baseHue, baseSat * 0.8, baseLightness * 1.2).hex(),
        chroma.hsl((baseHue + 180) % 360, baseSat * 0.7, baseLightness).hex(),
        chroma.hsl(baseHue, baseSat * 0.6, baseLightness * 0.8).hex(),
        chroma.hsl(baseHue, baseSat * 0.5, baseLightness * 1.4).hex(),
        chroma.hsl((baseHue + 180) % 360, baseSat * 0.65, baseLightness * 1.1).hex(), // Added complementary with different lightness
      ];

    default:
      return generatePaletteColors(PALETTE_STRATEGIES.ANALOGOUS);
  }
}

export function generateInitialColors(strategy = PALETTE_STRATEGIES.ANALOGOUS) {
  const colors = generatePaletteColors(strategy);

  const adjustedColors = colors.map(color => {
    const c = chroma(color);
    if (c.luminance() < 0.1) return c.luminance(0.15).hex();
    if (c.luminance() > 0.9) return c.luminance(0.85).hex();
    return color;
  });

  return adjustedColors.map((hex) => {
    const names = colorNamer(hex);
    return {
      hex: hex.replace('#', ''),
      name: names.ntc[0].name,
      role: ColorRole.NONE
    };
  });
}

export const loadCustomFont = async (file, fontName) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const fontBlob = new Blob([e.target.result], { type: file.type });
        const fontUrl = URL.createObjectURL(fontBlob);
        const fontFace = new FontFace(fontName, `url(${fontUrl})`);
        const loadedFont = await fontFace.load();
        document.fonts.add(loadedFont);
        localStorage.setItem('customFont', JSON.stringify({
          name: fontName,
          url: fontUrl,
          type: file.type
        }));
        resolve(fontName);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

export const loadStoredCustomFont = async () => {
  const storedFont = localStorage.getItem('customFont');
  if (!storedFont) return null;

  try {
    const { name, url } = JSON.parse(storedFont);
    const response = await fetch(url);
    const fontData = await response.blob();
    const fontFace = new FontFace(name, `url(${url})`);
    const loadedFont = await fontFace.load();
    document.fonts.add(loadedFont);
    return name;
  } catch (error) {
    console.error('Failed to load stored font:', error);
    localStorage.removeItem('customFont');
    return null;
  }
};

export const getContrastRatio = (color1, color2) => {
  return chroma.contrast(color1, color2);
};

export const shouldUseWhiteText = (backgroundColor) => {
  try {
    const whiteContrast = chroma.contrast(backgroundColor, '#ffffff');
    const blackContrast = chroma.contrast(backgroundColor, '#000000');
    return whiteContrast >= blackContrast;
  } catch (error) {
    console.warn('Error calculating contrast, defaulting to white text');
    return true;
  }
};

export const getTextColor = (backgroundColor) => {
  try {
    const color = chroma(backgroundColor);
    const luminance = color.luminance();

    if (luminance > 0.7) {
      return '#000000';
    }
    if (luminance < 0.3) {
      return '#ffffff';
    }
    return shouldUseWhiteText(backgroundColor) ? '#ffffff' : '#000000';
  } catch (error) {
    console.warn('Error calculating text color, defaulting to white');
    return '#ffffff';
  }
};

export const enhanceButtonColor = (baseColor) => {
  try {
    const color = chroma(baseColor);
    const luminance = color.luminance();

    return {
      backgroundColor: baseColor,
      textColor: getTextColor(baseColor),
      hoverColor: luminance > 0.7
        ? color.darken(0.1).hex()
        : color.brighten(0.1).hex(),
      activeColor: luminance > 0.7
        ? color.darken(0.2).hex()
        : color.brighten(0.2).hex(),
      borderColor: luminance > 0.8
        ? 'rgba(0,0,0,0.1)'
        : 'transparent',
      shadowColor: luminance > 0.8
        ? 'rgba(0,0,0,0.05)'
        : 'rgba(0,0,0,0.2)',
      focusRingColor: luminance > 0.8
        ? color.darken(0.2).alpha(0.5).css()
        : color.brighten(0.2).alpha(0.5).css()
    };
  } catch (error) {
    console.warn('Error enhancing button color, returning defaults');
    return {
      backgroundColor: baseColor,
      textColor: '#ffffff',
      hoverColor: baseColor,
      activeColor: baseColor,
      borderColor: 'transparent',
      shadowColor: 'rgba(0,0,0,0.1)',
      focusRingColor: 'rgba(255,255,255,0.5)'
    };
  }
};

export const generateRandomColor = () => ({
  hex: Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0'),
  name: ''
});

export const isValidHex = (hex) => {
  const cleanHex = hex.startsWith('#') ? hex.substring(1) : hex;
  return /^[0-9A-Fa-f]{6}$/.test(cleanHex);
};

export const getColorName = (hex) => {
  try {
    const name = colorNamer(`#${hex}`).ntc[0]?.name;
    return name ? name.charAt(0).toUpperCase() + name.slice(1) : '';
  } catch (e) {
    return '';
  }
};