import { X, LayoutGrid, LayoutList } from 'lucide-react';
import { useState, useRef, useEffect } from "react";

const FavoritesPanel = ({ onClose, onSelect, favorites, onRemoveFavorite }) => {
  const [viewMode, setViewMode] = useState('grid');
  const [isExiting, setIsExiting] = useState(false);
  const [isEntering, setIsEntering] = useState(true);
  const panelRef = useRef(null);

  useEffect(() => {
    requestAnimationFrame(() => {
      setIsEntering(false);
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      onClose();
      setIsExiting(false);
    }, 300);
  };

  return (
    <>
      <div 
        className={`fixed inset-0 bg-black/70 transition-opacity duration-300 z-40
          ${isExiting || isEntering ? 'opacity-0' : 'opacity-100'}`}
        onClick={handleClose}
      />
      
      <div 
        ref={panelRef} 
        className={`fixed top-0 right-0 h-screen w-80 bg-[hsl(var(--surface))] shadow-xl z-50 
          border-l-modes transition-all duration-300 transform
          ${isExiting || isEntering ? 'translate-x-full opacity-0' : 'translate-x-0 opacity-100'}
          flex flex-col`}
      >
        <header className="flex justify-between items-center p-4 border-b-modes">
          <div className="flex items-center gap-2">
            <h3 className="font-bold text-sm">Favorite Colors</h3>
            <div className="flex border-modes rounded overflow-hidden">
              <button
                onClick={() => setViewMode('grid')}
                className={`p-1 transition-colors ${viewMode === 'grid' ? 'bg-[hsl(var(--surface))] dark:bg-white text-black' : 'hover:bg-black/5'}`}
              >
                <LayoutGrid size={16} />
              </button>
              <button
                onClick={() => setViewMode('list')}
                className={`p-1 transition-colors ${viewMode === 'list' ? 'bg-[hsl(var(--surface))] dark:bg-white text-black' : 'hover:bg-black/5'}`}
              >
                <LayoutList size={16} />
              </button>
            </div>
          </div>
          <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
            <X size={16} />
          </button>
        </header>
        <div className={`p-4 overflow-y-auto ${viewMode === 'grid' ? 'grid grid-cols-2 gap-2' : 'flex flex-col gap-2'}`}>
          {favorites.length === 0 ? (
            <div className="col-span-2 text-center text-gray-500 py-8">
              No favorite colors yet
            </div>
          ) : (
            favorites.map(color => (
              viewMode === 'grid' ? (
                <div
                  key={color.hex}
                  className="relative group cursor-pointer"
                  onClick={() => onSelect(color)}
                >
                  <div
                    className="h-20 rounded-lg hover:ring-2 ring-blue-500"
                    style={{ backgroundColor: `#${color.hex}` }}
                  />
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveFavorite(color.hex);
                    }}
                    className="absolute -top-2 -right-2 opacity-0 group-hover:opacity-100 bg-white rounded-full text-black w-6 h-6 flex flex-col items-center justify-center border-2 border-gray-700 leading-tight shadow-md font-mono"
                  >
                    <X size={12} />
                  </button>
                  <div className="mt-1 text-xs">
                    <div className="font-medium truncate">{color.name}</div>
                    <div className="font-mono">#{color.hex}</div>
                  </div>
                </div>
              ) : (
                <div
                  key={color.hex}
                  className="flex items-center gap-3 p-2 hover:bg-black/5 rounded-lg cursor-pointer group"
                  onClick={() => onSelect(color)}
                >
                  <div
                    className="w-10 h-10 rounded-lg flex-shrink-0 hover:ring-2 ring-blue-500"
                    style={{ backgroundColor: `#${color.hex}` }}
                  />
                  <div className="flex-grow">
                    <div className="font-medium text-sm truncate">{color.name}</div>
                    <div className="font-mono text-xs">#{color.hex}</div>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveFavorite(color.hex);
                    }}
                    className="opacity-0 group-hover:opacity-100 p-1 hover:bg-black/10 rounded"
                  >
                    <X size={16} />
                  </button>
                </div>
              )
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default FavoritesPanel;