const ErrorPage = ({ children }) => {
  return(
    <main className="flex flex-col m-24 items-center">
      <div>
        <img
          src="/logo.png"
          alt="astral logo dark"
          width="100px"
          className="dark:block hidden"
        />
        <img
          src="/logo_black.png"
          alt="astral logo light"
          width="100px"
          className="dark:hidden block"
        />
      </div>
      <h1 className="text-[40vh] font-black tracking-tight">
        404
      </h1>
      <h2 className="text-slate-600">
        Weird. This page doesn't exist. You could try going <a href="/" className="text-blue-700 border-b border-blue-700">home</a>.
      </h2>
    </main>
  )
}

export default ErrorPage;