import { useState } from 'react';
import { nanoid } from 'nanoid';
import { supabase } from '../lib/supabase';
import { generateColorScale, enhanceButtonColor } from '../components/utils';

export function useConfiguration() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Enhanced color normalization that includes full color scales
  const normalizeColorsForStorage = (colors) => {
    try {
      return colors.map(color => ({
        hex: color.hex.replace('#', ''),
        name: color.name,
        role: color.role,
        scale: generateColorScale(`#${color.hex}`)
      }));
    } catch (err) {
      console.error('Error normalizing colors for storage:', err);
      return colors;
    }
  };

  // Normalize colors when retrieving from storage
  const normalizeColorsFromStorage = (colors) => {
    try {
      return colors.map(color => ({
        ...color,
        hex: color.hex.replace('#', ''),
        scale: color.scale || generateColorScale(`#${color.hex}`), // fallback for legacy data
        buttonProperties: color.buttonProperties || enhanceButtonColor(`#${color.hex}`) // fallback for legacy data
      }));
    } catch (err) {
      console.error('Error normalizing colors from storage:', err);
      return colors;
    }
  };

  const saveConfiguration = async (configData) => {
    console.log('saveConfiguration starting with data:', configData);
    try {
      setLoading(true);
      setError(null);

      const id = nanoid(10);
      const normalizedColors = normalizeColorsForStorage(configData.colors);
      console.log('Normalized colors:', normalizedColors);

      const payload = {
        id,
        colors: normalizedColors,
        type_scale: configData.typeScale,
        font_family: configData.fontFamily,
        custom_font: configData.customFont,
        h1_size: configData.h1Size,
        leading_values: configData.leadingValues,
        updated_at: new Date().toISOString()
      };

      console.log('Sending payload to Supabase:', payload);

      const { data, error: saveError } = await supabase
        .from('configurations')
        .insert([payload])
        .select()
        .single();

      if (saveError) {
        console.error('Supabase save error:', saveError);
        throw saveError;
      }

      console.log('Save successful:', data);

      return {
        id: data.id,
        shareUrl: `${window.location.origin}/config/${data.id}`
      };
    } catch (err) {
      console.error('Save configuration error:', err);
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const getConfiguration = async (id) => {
    try {
      setLoading(true);
      setError(null);

      const { data, error: fetchError } = await supabase
        .from('configurations')
        .select('*')
        .eq('id', id)
        .single();

      if (fetchError) throw fetchError;
      if (!data) throw new Error('Configuration not found');

      const colors = normalizeColorsFromStorage(data.colors);

      return {
        colors,
        colorScales: data.color_scales || {},
        typeScale: data.type_scale,
        fontFamily: data.font_family,
        customFont: data.custom_font,
        h1Size: data.h1_size,
        leadingValues: data.leading_values || {
          h1: 'leading-normal',
          h2: 'leading-normal',
          h3: 'leading-normal',
          h4: 'leading-normal',
          h5: 'leading-normal',
          h6: 'leading-normal',
          body: 'leading-normal'
        },
        metadata: data.metadata || {
          version: '1.0',
          generated_at: data.updated_at,
          color_strategy: 'legacy'
        }
      };
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updateConfiguration = async (id, configData) => {
    try {
      setLoading(true);
      setError(null);

      const normalizedColors = normalizeColorsForStorage(configData.colors);
      const colorScales = {};
      normalizedColors.forEach(color => {
        colorScales[color.role || 'none'] = color.scale;
      });

      const { data, error: updateError } = await supabase
        .from('configurations')
        .update({
          colors: normalizedColors,
          color_scales: colorScales,
          type_scale: configData.typeScale,
          font_family: configData.fontFamily,
          custom_font: configData.customFont,
          h1_size: configData.h1Size,
          leading_values: configData.leadingValues,
          metadata: {
            ...configData.metadata,
            updated_at: new Date().toISOString()
          },
          updated_at: new Date().toISOString()
        })
        .eq('id', id)
        .select()
        .single();

      if (updateError) throw updateError;

      return {
        id: data.id,
        shareUrl: `${window.location.origin}/config/${data.id}`
      };
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    saveConfiguration,
    getConfiguration,
    updateConfiguration,
    loading,
    error
  };
}