import React, { useEffect } from 'react';
import Button from './Button';
import { ColorRole, generateColorScale, headingSizes } from './utils';
import { normalizeHex } from './utils';

const ColorPreview = ({ colors }) => {
  return (
    // renders color palettes
    <div className="w-full space-y-8">
      {colors.map((color, colorIndex) => {
        const scale = generateColorScale(color.hex);
        return (
          <div key={colorIndex} className="w-full space-y-4">
            <div className="flex gap-3">
              <div
                className="w-6 h-6 rounded-full flex-shrink-0"
                style={{ backgroundColor: normalizeHex(color.hex) }}
              />
              <h4 className="font-medium">
                {color.name}
                {color.role !== ColorRole.NONE && (
                  <span className="ml-2 text-sm text-gray-500">({color.role})</span>
                )}
              </h4>
            </div>

            <div className="w-full grid grid-cols-9 gap-2">
            {Object.entries(scale).map(([shade, value]) => (
              <div key={shade} className="w-full">
                <div
                  className={`
                    w-full aspect-square rounded shadow-sm
                    ${value.replace('#', '') === color.hex.replace('#', '') ? 'ring-2 ring-red-500 ring-offset-2' : ''}
                  `}
                  style={{ backgroundColor: value }}
                />
                <div className="text-xs mt-1 font-medium text-center">{shade}</div>
                <div
                  className="text-xs font-mono truncate text-center"
                  title={value}
                >
                  {value}
                </div>
              </div>
            ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TypePreview = ({ fontFamily, typeConfig, leadingValues = {}, onLeadingChange = () => { } }) => {
  const sampleText = "Pack my box with five brown liquor jugs";
  const paragraphText = "It suddenly struck me that that tiny pea, pretty and blue, was the Earth. I put up my thumb and shut one eye, and my thumb blotted out the planet Earth. I didn't feel like a giant. I felt very, very small."

  const defaultLeading = {
    h1: 'leading-normal',
    h2: 'leading-normal',
    h3: 'leading-normal',
    h4: 'leading-normal',
    h5: 'leading-normal',
    h6: 'leading-normal',
    body: 'leading-normal',
    ...leadingValues
  };

  const leadingOptions = {
    'leading-none': '1',
    'leading-tight': '1.25',
    'leading-snug': '1.375',
    'leading-normal': '1.5',
    'leading-relaxed': '1.625',
    'leading-loose': '2'
  };

  const handleLeadingChange = (tag, value) => {
    onLeadingChange({
      ...defaultLeading,
      [tag]: value
    });
  };

  return (
    <div className="space-y-8" style={{ fontFamily: `'${fontFamily}', sans-serif` }}>
      {['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].map((tag) => {
        const size = typeConfig[tag].size.startsWith('text-[')
          ? typeConfig[tag].size.match(/\[(.*?)rem/)[1]
          : headingSizes[typeConfig[tag].size];

        const leading = defaultLeading[tag];

        return (
          <div key={tag} className="pb-6 border-b-modes last:border-0">
            <div className="text-sm text-gray-500 mb-2 font-mono">
              {tag.toUpperCase()} - {typeConfig[tag].size} - {leading}
            </div>
            <div style={{ fontSize: `${size}rem` }} className={`font-bold tracking-tight ${leading}`}>
              {sampleText}
            </div>
            <div style={{ fontSize: `${size}rem` }} className={`font-normal tracking-tight ${leading}`}>
              {sampleText}
            </div>
            <div className="flex gap-1 mt-4 border-modes w-fit p-px rounded-full">
              {Object.entries(leadingOptions).map(([name]) => (
                <button
                  key={name}
                  onClick={() => handleLeadingChange(tag, name)}
                  className={`px-3 py-1 text-sm rounded-full transition-colors ${leading === name
                      ? 'bg-blue-500 text-white'
                      : 'bg-[hsl(va(--surface))] hover:bg-gray-200 dark:hover:bg-slate-800'
                    }`}
                >
                  {name.replace('leading-', '')}
                </button>
              ))}
            </div>
          </div>
        );
      })}

      <div>
        <div className="text-sm text-gray-500 mb-2 font-mono">
          Body - text-base - {defaultLeading.body}
        </div>
        <p className={`w-2/3 text-base ${defaultLeading.body}`}>
          {paragraphText}
        </p>
        <div className="flex gap-1 mt-4 border-modes w-fit p-px rounded-full">
          {Object.entries(leadingOptions).map(([name]) => (
            <button
              key={name}
              onClick={() => handleLeadingChange('body', name)}
              className={`px-3 py-1 text-sm rounded-full transition-colors ${defaultLeading.body === name
                  ? 'bg-blue-500 text-white'
                  : 'bg-[hsl(va(--surface))] hover:bg-gray-200 dark:hover:bg-slate-800'
                }`}
            >
              {name.replace('leading-', '')}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const Preview = ({ colors, fontFamily, typeConfig, activeTab, leadingValues, setLeadingValues = () => { } }) => {
  useEffect(() => {
    const link = document.createElement('link');
    link.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(' ', '+')}:wght@400;700&display=swap`;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    return () => document.head.removeChild(link);
  }, [fontFamily]);

  return (
    <div className="p-8 h-fit">
      {activeTab === 'colors' && <ColorPreview colors={colors} />}
      {activeTab === 'type' && (
        <TypePreview
          fontFamily={fontFamily}
          typeConfig={typeConfig}
          leadingValues={leadingValues}
          onLeadingChange={setLeadingValues}
        />
      )}
    </div>
  );
};

export default Preview;