import React, { useState } from 'react';
import { supabase } from '../lib/supabase';

export const Auth = ({ onSuccess }) => {
  const [isRedirecting, setIsRedirecting] = useState(false);

  const handleOAuthLogin = async (provider) => {
    try {
      setIsRedirecting(true);
      
      await supabase.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo: window.location.origin
        }
      });
      
      onSuccess?.();
    } catch (error) {
      console.error('OAuth error:', error.message);
      setIsRedirecting(false);
    }
  };

  if (isRedirecting) {
    return (
      <div className="text-center py-4">
        <div className="animate-spin h-8 w-8 border-4 border-blue-500 border-t-transparent rounded-full mx-auto mb-4" />
        <p>Redirecting to login...</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <button
        onClick={() => handleOAuthLogin('google')}
        className="w-full flex items-center justify-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50 dark:hover:bg-gray-800"
      >
        Continue with Google
      </button>

      <button
        onClick={() => handleOAuthLogin('github')}
        className="w-full flex items-center justify-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50 dark:hover:bg-gray-800"
      >
        Continue with GitHub
      </button>
    </div>
  );
};