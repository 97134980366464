// hooks/useSettings.js
import { useState, useEffect } from 'react';
import { PALETTE_STRATEGIES } from '../components/utils';

const DEFAULT_SETTINGS = {
  theme: 'system',
  colorStrategy: PALETTE_STRATEGIES.ANALOGOUS
};

export const useSettings = () => {
  // Initialize state with stored settings or defaults
  const [settings, setSettingsState] = useState(() => {
    try {
      const storedSettings = localStorage.getItem('redstone_settings');
      return storedSettings ? JSON.parse(storedSettings) : DEFAULT_SETTINGS;
    } catch (error) {
      console.error('Error loading settings:', error);
      return DEFAULT_SETTINGS;
    }
  });

  // Wrapper function to update both state and localStorage
  const setSettings = (newSettings) => {
    try {
      // Handle both function and direct value updates
      const updatedSettings = typeof newSettings === 'function'
        ? newSettings(settings)
        : newSettings;

      setSettingsState(updatedSettings);
      localStorage.setItem('redstone_settings', JSON.stringify(updatedSettings));
    } catch (error) {
      console.error('Error saving settings:', error);
    }
  };

  // Optional: Sync settings across tabs/windows
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'redstone_settings') {
        try {
          const newSettings = JSON.parse(e.newValue);
          setSettingsState(newSettings);
        } catch (error) {
          console.error('Error syncing settings:', error);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return [settings, setSettings];
};