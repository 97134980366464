import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import { useConfiguration } from '../hooks/useConfiguration';
import Home from './Home';

function ConfigView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getConfiguration } = useConfiguration();

  useEffect(() => {
    async function loadConfig() {
      try {
        const data = await getConfiguration(id);
        setConfig({
          ...data,
          id // Add the id to the config object
        });
      } catch (err) {
        console.error('Error loading configuration:', err);
        navigate('/');
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      loadConfig();
    }
  }, [id, navigate, getConfiguration]);

  if (loading) {
    return (
      <Layout>
        <div className="flex items-center justify-center h-screen">
          <div className="text-lg">Loading configuration...</div>
        </div>
      </Layout>
    );
  }

  if (!config) {
    return (
      <Layout>
        <div className="flex items-center justify-center h-screen">
          <div className="text-lg">Configuration not found</div>
        </div>
      </Layout>
    );
  }

  return <Home initialConfig={config} isReadOnly={true} />;
}

export default ConfigView;