import React, { useState } from 'react';
import Layout from '../components/Layout';
import chroma from 'chroma-js';
import { getContrastRatio, normalizeHex } from '../components/utils';

const AllyView = () => {
  const [foreground, setForeground] = useState('#000000');
  const [background, setBackground] = useState('#FFFFFF');
  const [suggestions, setSuggestions] = useState(null);

  const generateAlternatives = (baseColor, otherColor, targetRatio = 4.5) => {
    try {
      const color = chroma(baseColor);
      const alternatives = [];

      // Generate alternatives by adjusting luminance
      for (let i = 0; i <= 1; i += 0.1) {
        const adjusted = color.luminance(i);
        if (getContrastRatio(adjusted.hex(), otherColor) >= targetRatio) {
          alternatives.push(adjusted.hex());
        }
      }

      // Generate alternatives by rotating hue slightly
      [-15, -10, -5, 5, 10, 15].forEach(hueShift => {
        const adjusted = color.set('hsl.h', color.get('hsl.h') + hueShift).saturate(0.1);
        if (getContrastRatio(adjusted.hex(), otherColor) >= targetRatio) {
          alternatives.push(adjusted.hex());
        }
      });

      // Remove duplicates and sort by similarity to original color
      return [...new Set(alternatives)]
        .sort((a, b) => {
          const distA = chroma.distance(baseColor, a);
          const distB = chroma.distance(baseColor, b);
          return distA - distB;
        })
        .slice(0, 5);
    } catch (error) {
      console.error('Error generating alternatives:', error);
      return [];
    }
  };

  const handleColorChange = (value, type) => {
    const cleanValue = value.slice(0, 7);
    if (type === 'foreground') {
      setForeground(cleanValue);
    } else {
      setBackground(cleanValue);
    }

    // Generate alternatives if we don't have any yet
    if (!suggestions) {
      const fg = type === 'foreground' ? cleanValue : foreground;
      const bg = type === 'background' ? cleanValue : background;
      const normalizedFg = normalizeHex(fg);
      const normalizedBg = normalizeHex(bg);

      if (isValidHex(normalizedFg) && isValidHex(normalizedBg)) {
        const ratio = getContrastRatio(normalizedFg, normalizedBg);
        if (ratio < 4.5) {
          setSuggestions({
            foreground: generateAlternatives(normalizedFg, normalizedBg),
            background: generateAlternatives(normalizedBg, normalizedFg)
          });
        }
      }
    }
  };

  const isValidHex = (color) => /^#[0-9A-Fa-f]{6}$/.test(color);
  const normalizedFg = normalizeHex(foreground);
  const normalizedBg = normalizeHex(background);

  const contrastRatio = (isValidHex(normalizedFg) && isValidHex(normalizedBg))
    ? getContrastRatio(normalizedFg, normalizedBg)
    : null;

  return (
    <Layout>
      <main className="p-8">
        <div className="flex flex-col gap-6">
          <div className="flex justify-center gap-6">
            <div className="flex flex-row gap-2 w-full">
              <button
                onClick={() => document.getElementById('foreground-color').click()}
                className="w-12 h-12 rounded-lg border border-gray-200 cursor-pointer"
                style={{ backgroundColor: normalizeHex(foreground) }}
              >
                <input
                  type="color"
                  id="foreground-color"
                  value={normalizeHex(foreground)}
                  onChange={(e) => {
                    // Color picker always returns values with #
                    handleColorChange(e.target.value, 'foreground');
                  }}
                  className="sr-only"
                />
              </button>
              <input
                type="text"
                value={foreground}
                onChange={(e) => handleColorChange(e.target.value, 'foreground')}
                placeholder="#000000"
                className="w-full text-xl p-4 border-modes rounded-xl bg-[hsl(var(--surface))] focus:border-sky-500 focus:outline-none"
              />
            </div>
            <div className="flex flex-row gap-2 w-full">
              <button
                onClick={() => document.getElementById('background-color').click()}
                className="w-12 h-12 rounded-lg border border-gray-200 cursor-pointer"
                style={{ backgroundColor: normalizeHex(background) }}
              >
                <input
                  type="color"
                  id="background-color"
                  value={normalizeHex(background)}
                  onChange={(e) => {
                    // Color picker always returns values with #
                    handleColorChange(e.target.value, 'background');
                  }}
                  className="sr-only"
                />
              </button>
              <input
                type="text"
                value={background}
                onChange={(e) => handleColorChange(e.target.value, 'background')}
                placeholder="#FFFFFF"
                className="w-full text-xl p-4 border-modes rounded-xl bg-[hsl(var(--surface))] focus:border-sky-500 focus:outline-none"
              />
            </div>
          </div>

          {(isValidHex(normalizedFg) && isValidHex(normalizedBg)) && (
            <div
              className="p-8 text-center rounded-xl text-xl"
              style={{
                backgroundColor: normalizedBg,
                color: normalizedFg
              }}
            >
              Sample Text Preview
            </div>
          )}

          {contrastRatio !== null && (
            <div className="space-y-4 text-xl">
              <p>Contrast Ratio: {contrastRatio.toFixed(2)}</p>
              <div className="space-y-2">
                <p className={contrastRatio >= 3 ? 'text-green-600' : 'text-red-600'}>
                  Level AA (Large Text): {contrastRatio >= 3 ? 'Pass' : 'Fail'}
                </p>
                <p className={contrastRatio >= 4.5 ? 'text-green-600' : 'text-red-600'}>
                  Level AA (Normal Text): {contrastRatio >= 4.5 ? 'Pass' : 'Fail'}
                </p>
                <p className={contrastRatio >= 7 ? 'text-green-600' : 'text-red-600'}>
                  Level AAA: {contrastRatio >= 7 ? 'Pass' : 'Fail'}
                </p>
              </div>

              {suggestions && (
                <div className="space-y-4 mt-6">
                  <h2 className="font-bold text-xl">Suggested Accessible Alternatives</h2>

                  {suggestions.foreground.length > 0 && (
                    <div className="space-y-2">
                      <p className="font-medium">Alternative Text Colors:</p>
                      <div className="flex gap-2">
                        {suggestions.foreground.map((color, index) => (
                          <button
                            key={index}
                            onClick={() => handleColorChange(color, 'foreground')}
                            className={`w-12 h-12 rounded-lg cursor-pointer transition-all duration-150
                              ${color === normalizedFg ? 'ring-2 ring-sky-500' : 'border border-gray-200'}`}
                            style={{ backgroundColor: color }}
                            title={color}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  {suggestions.background.length > 0 && (
                    <div className="space-y-2">
                      <p className="font-medium">Alternative Background Colors:</p>
                      <div className="flex gap-2">
                        {suggestions.background.map((color, index) => (
                          <button
                            key={index}
                            onClick={() => handleColorChange(color, 'background')}
                            className={`w-12 h-12 rounded-lg cursor-pointer transition-all duration-150
                              ${color === normalizedBg ? 'ring-2 ring-sky-500' : 'border border-gray-200'}`}
                            style={{ backgroundColor: color }}
                            title={color}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default AllyView;