import React, { useRef, useEffect } from 'react';
import { isValidHex, getColorName } from '../utils';
import ColorRoleEditor from './ColorRoleEditor';

const ColorDetailEditor = ({
  previewState,
  colorInputRef,
  handleColorPickerChange,
  setPreviewState,
  index,
  onSave,
  onRemove,
  isReadOnly
}) => {
  const editorRef = useRef(null);

  useEffect(() => {
    const editor = editorRef.current;
    if (!editor) return;

    // Get editor dimensions and position
    const rect = editor.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate offsets to keep editor in viewport
    let xOffset = 0;
    let yOffset = 0;

    if (rect.right > viewportWidth) {
      xOffset = viewportWidth - rect.right - 16;
    }
    if (rect.left < 0) {
      xOffset = -rect.left + 16;
    }
    if (rect.bottom > viewportHeight) {
      yOffset = viewportHeight - rect.bottom - 16;
    }

    if (xOffset !== 0 || yOffset !== 0) {
      editor.style.transform = `translate(calc(-50% + ${xOffset}px), ${yOffset}px)`;
    }
  }, []);

  const handleSave = () => {
    if (previewState.hex && isValidHex(previewState.hex)) {
      onSave(index, previewState);
    } else {
      alert("Please enter a valid hex code (e.g., ABC or ABCDEF).");
    }
  };

  const handleRoleChange = (newRole) => {
    setPreviewState(prev => ({
      ...prev,
      role: newRole
    }));
  };

  const handleColorChange = (e) => {
    const newColor = e.target.value;
    setPreviewState(prev => ({
      ...prev,
      hex: newColor,
      name: isValidHex(newColor.replace('#', '')) ? getColorName(newColor.replace('#', '')) : prev.name
    }));
    // Update the color input reference
    if (colorInputRef.current) {
      colorInputRef.current.value = newColor.startsWith('#') ? newColor.substring(1) : newColor;
    }
  };

  return (
    <div ref={editorRef} className="w-80 bg-[hsl(var(--surface))] border-modes rounded-xl shadow-2xl">
      <div className="space-y-4">
        <div>
          <header className="flex p-4 justify-center">
            <h3 className="font-bold text-sm text-black dark:text-white">Color Details</h3>
          </header>
          <div className="flex gap-2 px-4 py-2">
            <input
              type="color"
              value={previewState.hex.startsWith('#') ? previewState.hex : `#${previewState.hex}`}
              onChange={handleColorChange}
              className="pickertrigger w-10 h-10 rounded bg-transparent input-border cursor-pointer"
              disabled={isReadOnly}
              onClick={(e) => e.stopPropagation()}
            />
            <input
              ref={colorInputRef}
              type="text"
              value={previewState.hex.startsWith('#') ? previewState.hex.substring(1) : previewState.hex}
              onChange={(e) => setPreviewState(prev => {
                const newHex = e.target.value.replace(/[^0-9A-Fa-f]/g, '').slice(0, 6);
                return {
                  ...prev,
                  hex: prev.hex.startsWith('#') ? `#${newHex}` : newHex,
                  name: isValidHex(newHex) ? getColorName(newHex) : prev.name
                };
              })}
              className="flex-1 px-2 py-1 rounded font-mono text-sm input-border bg-[hsl(var(--surface))]"
              placeholder="Enter hex code"
              disabled={isReadOnly}
            />
          </div>
          <div className="px-4 py-2">
            <input
              type="text"
              value={previewState.name || ''}
              onChange={(e) => setPreviewState(prev => ({ ...prev, name: e.target.value }))}
              placeholder="Color name"
              className="w-full px-2 py-1 rounded font-mono text-sm input-border bg-[hsl(var(--surface))]"
              disabled={isReadOnly}
            />
          </div>
          <div className="px-4 py-2">
            <ColorRoleEditor
              color={previewState}
              onRoleChange={handleRoleChange}
              disabled={isReadOnly}
            />
          </div>
        </div>
        {!isReadOnly && (
          <div className="flex flex-row py-3 px-3 gap-3">
            <button
              onClick={handleSave}
              className="w-full px-3 py-1 text-sm focus:outline-none bg-green-700 text-green-200 hover:bg-green-900 rounded-md"
            >
              Save Color
            </button>
            <button
              onClick={onRemove}
              className="w-full px-3 py-1 text-sm text-red-600 hover:text-red-800 focus:outline-none border border-red-300 rounded-md bg-red-100"
            >
              Remove Color
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorDetailEditor;