import React, { useEffect, useRef } from 'react';
import { Bookmark } from 'lucide-react';
import { shouldUseWhiteText } from '../utils';

const SwatchCard = ({
  color,
  isActive,
  previewState,
  onClick,
  isReadOnly,
  onToggleFavorite,
  isFavorite,
  children
}) => {
  const displayColor = (isActive && previewState) ? previewState : color;
  const isWhiteText = shouldUseWhiteText(displayColor.hex);
  const textColorClass = isWhiteText ? 'text-white' : 'text-black';

  const popoverRef = useRef(null);
  const colorInputRef = useRef(null);

  const handleColorChange = (event) => {
    const newColor = event.target.value;
    console.log('Selected color:', newColor);
  };

  const openColorPicker = () => {
    colorInputRef.current.click();
  };

  useEffect(() => {
    const adjustPopoverPosition = () => {
      if (popoverRef.current) {
        const rect = popoverRef.current.getBoundingClientRect();
        const isOverflowingRight = rect.right > window.innerWidth;
        const isOverflowingBottom = rect.bottom > window.innerHeight;

        if (isOverflowingRight) {
          popoverRef.current.style.left = `-${rect.width}px`;
        }
        if (isOverflowingBottom) {
          popoverRef.current.style.top = `-${rect.height}px`;
        }
      }
    };

    window.addEventListener('resize', adjustPopoverPosition);
    adjustPopoverPosition();

    return () => {
      window.removeEventListener('resize', adjustPopoverPosition);
    };
  }, []);

  const getRoleBadgeStyle = () => {
    if (!displayColor.role || displayColor.role === 'none') return null;

    return {
      backgroundColor: isWhiteText ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',
      backdropFilter: 'blur(4px)',
    };
  };

  const formatRoleLabel = (role) => {
    if (!role || role === 'none') return null;
    return role.charAt(0).toUpperCase() + role.slice(1);
  };

  return (
    <div className="relative group" onClick={onClick}>
      <div className={`w-full h-full absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition-opacity duration-150 ${isWhiteText ? 'bg-white/20' : 'bg-black/20'}`}></div>
      <div
        className="aspect-square cursor-pointer"
        style={{ backgroundColor: displayColor.hex.startsWith('#') ? displayColor.hex : `#${displayColor.hex}` }}
        onClick={openColorPicker}
      >
        <div className={`
          absolute inset-0 p-4
          flex flex-col justify-between
          ${textColorClass}
        `}>
          {/* Top section with name and favorite button */}
          <div className={`flex justify-between items-start pb-0.5 border-b ${isWhiteText ? 'border-white' : 'border-black'}`}>
            <span className="text-sm">
              {displayColor.name || 'Untitled'}
            </span>
            {!isReadOnly && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onToggleFavorite(displayColor);
                }}
                className={`
                  p-1 rounded-full
                  ${isWhiteText ? 'hover:bg-white/20' : 'hover:bg-black/20'}
                  transition-colors duration-200
                `}
              >
                <Bookmark
                  size={16}
                  className={`
                    ${isFavorite ? 'fill-current' : 'fill-none'}
                    transition-colors duration-200
                  `}
                />
              </button>
            )}
          </div>

          {/* Bottom section with hex code and role badge */}
          <div className="flex justify-between items-end">
            <div className={`
              text-base font-mono
              ${isWhiteText ? 'text-white/70' : 'text-black/70'}
            `}>
              {displayColor.hex.startsWith('#') ? displayColor.hex : `#${displayColor.hex}`}
            </div>
            {displayColor.role && displayColor.role !== 'none' && (
              <div
                className="inline-block px-2 py-0.5 rounded-full text-xs font-medium"
                style={getRoleBadgeStyle()}
              >
                {formatRoleLabel(displayColor.role)}
              </div>
            )}
          </div>
        </div>
      </div>
      <input
        type="color"
        ref={colorInputRef}
        className="hidden"
        onChange={handleColorChange}
      />
      <div className="absolute z-50 left-0 right-0 top-full mt-2" ref={popoverRef}>
        {children}
      </div>
    </div>
  );
};

export default SwatchCard;