const AlertView = ({ children, setAlertOpen }) => {
  return (
    <main onClick={() => setAlertOpen(false)} className="overlay w-full h-full fixed bg-black/70 flex flex-col items-center justify-center z-[999] fadeIn">
      <div onClick={e => e.stopPropagation()} className="zoomIn flex flex-col rounded-xl bg-white border-modes bg=[hsl(var(--surface))] text-slate-800 w-1/3 overflow-hidden">

        <header className="flex justify-center p-4 border-b border-gray-300">
          <h3 className="text-lg font-bold">Settings</h3>
          <hr className="h-px my-2 bg-gray-600 border-0 dark:bg-gray-700" />
        </header>

        <div className="p-6 flex flex-col gap-2">
          { children }
        </div>

        <footer className="buttons flex flex-col">
          <button className="w-full py-2.5 border-t border-b border-gray-300 hover:bg-gray-200">
            Ok
          </button>
        </footer>

      </div>
    </main>
  )
}

export default AlertView;