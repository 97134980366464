import React, { useState, useEffect } from 'react';
import { useSettings } from '../hooks/useSettings';
import { PALETTE_STRATEGIES } from '../components/utils';
import { ChevronDown, Sun, Moon, MonitorCheck } from 'lucide-react';

const SettingsView = ({ setSettingsOpen }) => {
  const [settings, setSettings] = useSettings();
  const [isExiting, setIsExiting] = useState(false);
  const [isEntering, setIsEntering] = useState(true);
  const [tempSettings, setTempSettings] = useState({
    theme: settings?.theme || 'system',
    colorStrategy: settings?.colorStrategy || PALETTE_STRATEGIES.ANALOGOUS
  });

  // Handle entrance animation
  useEffect(() => {
    requestAnimationFrame(() => {
      setIsEntering(false);
    });
  }, []);

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      setSettingsOpen(false);
    }, 300); // Match this with your CSS transition duration
  };

  const handleSave = () => {
    setSettings(tempSettings);
    handleClose();
  };

  return (
    <main 
      onClick={handleClose} 
      className={`overlay w-full h-full fixed bg-black/70 flex flex-col items-center justify-center z-[999] transition-opacity duration-300 top-0
        ${isExiting || isEntering ? 'opacity-0' : 'opacity-100'}`}
    >
      <div 
        onClick={e => e.stopPropagation()} 
        className={`flex flex-col rounded-x border-modes bg-[hsl(var(--surface))] text-slate-800 w-1/3 
          overflow-hidden rounded-xl shadow-2xl transition-all duration-300 transform
          ${isExiting || isEntering ? 'scale-95 opacity-0' : 'scale-100 opacity-100'}`}
      >
        <header className="flex p-4 justify-center">
          <h3 className="font-bold text-xl text-black dark:text-white">Settings</h3>
        </header>
        <div className="p-6 flex flex-col gap-2">
          <p className="font-bold text-black dark:text-white">Theme</p>
          <p className="text-sm text-black dark:text-white mb-2">Choose your preferred color scheme.</p>
          <div className="flex flex-row rounded-md p-1 border-modes">
            {[
              { value: 'light', icon: <Sun size={24} /> },
              { value: 'dark', icon: <Moon size={24} /> },
              { value: 'system', icon: <MonitorCheck size={24} /> }
            ].map((option) => (
              <button
                key={option.value}
                onClick={() => setTempSettings({ ...tempSettings, theme: option.value })}
                className={`
                  flex justify-center w-full py-2 text-black dark:text-white rounded-md
                  ${tempSettings.theme === option.value
                    ? 'bg-blue-500 text-white'
                    : 'hover:bg-gray-100 dark:hover:bg-gray-800'
                  }
                `}
              >
                {option.icon}
              </button>
            ))}
          </div>
        </div>
        <div className="p-6 pt-0 flex flex-col gap-2">
          <p className="font-bold text-black dark:text-white">Color Strategy</p>
          <p className="text-sm text-black dark:text-white mb-2">Choose how the app generates color harmonies.</p>
          <div className="relative">
            <select
              value={tempSettings.colorStrategy}
              onChange={(e) => {
                setTempSettings({ ...tempSettings, colorStrategy: e.target.value });
              }}
              className="w-full p-2 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none input-border bg-[hsl(var(--surface))] text-black dark:text-white"
            >
              {Object.values(PALETTE_STRATEGIES).map((strategy) => (
                <option key={strategy} value={strategy}>
                  {strategy}
                </option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDown className="w-5 h-5 text-gray-500" />
            </div>
          </div>
        </div>
        <footer className="buttons flex flex-col">
          <button
            onClick={handleSave}
            className="w-full py-2.5 border-t-modes text-black dark:text-white bg-[hsl(var(--surface))] hover:bg-black/10"
          >
            Save
          </button>
          <button
            onClick={handleClose}
            className="w-full py-2.5 border-t-modes border-b-modes bg-[hsl(var(--surface))] hover:bg-black/10 text-red-500"
          >
            Cancel
          </button>
        </footer>
      </div>
    </main>
  );
};

export default SettingsView;