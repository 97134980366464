import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Configurator from '../components/Configurator';
import Preview from '../components/Preview';
import ColorSwatch from '../components/ui/ColorSwatch';
import { useConfiguration } from '../hooks/useConfiguration';

import {
  ColorRole,
  generateColorScale,
  headingSizes,
  typeScales,
  generateInitialColors,
  loadStoredCustomFont
} from '../components/utils';


const Home = ({ initialConfig, isReadOnly }) => {
  const { saveConfiguration, updateConfiguration, loading: isSaving } = useConfiguration();
  const [configId, setConfigId] = useState(null);
  const [shareUrl, setShareUrl] = useState(null);

  const getInitialColors = () => {
    try {
      const initialColors = initialConfig?.colors || generateInitialColors();
      return initialColors;
    } catch (error) {
      console.error('Error initializing colors:', error);
      return generateInitialColors();
    }
  };

  const [colors, setColors] = useState(getInitialColors);

  const [typeScale, setTypeScale] = useState(initialConfig?.typeScale || 'minor-third');
  const [fontFamily, setFontFamily] = useState(initialConfig?.fontFamily || 'Inter');
  const [customFont, setCustomFont] = useState(initialConfig?.customFont || null);
  const [h1Size, setH1Size] = useState(initialConfig?.h1Size || 'text-4xl');
  const [configVisible, setConfigVisible] = useState(true);
  const [activeTab, setActiveTab] = useState('colors');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [leadingValues, setLeadingValues] = useState(initialConfig?.leadingValues || {
    h1: 'leading-normal',
    h2: 'leading-normal',
    h3: 'leading-normal',
    h4: 'leading-normal',
    h5: 'leading-normal',
    h6: 'leading-normal',
    body: 'leading-normal'
  });


  // Set configId when loading an existing configuration
  useEffect(() => {
    if (initialConfig?.id) {
      setConfigId(initialConfig.id);
      setShareUrl(`${window.location.origin}/config/${initialConfig.id}`);
    }
  }, [initialConfig]);

  // Load stored custom font on mount
  useEffect(() => {
    if (!initialConfig?.customFont) {
      loadStoredCustomFont().then(fontName => {
        if (fontName) setCustomFont(fontName);
      });
    }
  }, [initialConfig]);

  // Add beforeunload event listener
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const handleConfigChange = () => {
    if (!isReadOnly) {
      setHasUnsavedChanges(true);
    }
  };

  const generateConfig = () => {
    const colorScales = {};
    colors.forEach(({ hex, name, role }) => {
      const safeName = role !== ColorRole.NONE ? role : name.toLowerCase().replace(/[^a-z0-9]/g, '-');
      const hexColor = hex.startsWith('#') ? hex : `#${hex}`;
      colorScales[safeName] = generateColorScale(hexColor);
    });

    const baseSize = headingSizes[h1Size];
    const scale = typeScales[typeScale];
    const typeConfig = {
      h1: {
        size: h1Size,
        leading: leadingValues.h1
      },
      h2: {
        size: `text-[${(baseSize / scale).toFixed(3)}rem]`,
        leading: leadingValues.h2
      },
      h3: {
        size: `text-[${(baseSize / (scale * scale)).toFixed(3)}rem]`,
        leading: leadingValues.h3
      },
      h4: {
        size: `text-[${(baseSize / (scale * scale * scale)).toFixed(3)}rem]`,
        leading: leadingValues.h4
      },
      h5: {
        size: `text-[${(baseSize / (scale * scale * scale * scale)).toFixed(3)}rem]`,
        leading: leadingValues.h5
      },
      h6: {
        size: `text-[${(baseSize / (scale * scale * scale * scale * scale)).toFixed(3)}rem]`,
        leading: leadingValues.h6
      },
      body: {
        size: 'text-base',
        leading: leadingValues.body
      }
    };

    const fontUrl = customFont
      ? null
      : `https://fonts.googleapis.com/css2?family=${fontFamily.replace(' ', '+')}:wght@400;700&display=swap`;

    const fontConfig = customFont
      ? `['${customFont}', 'sans-serif']`
      : `['${fontFamily}', 'sans-serif']`;

    return {
      css: `/* Add this to your CSS */
${customFont ? `@font-face {
  font-family: '${customFont}';
  src: url('path-to-your-font-file');
}` : `@import url('${fontUrl}');`}`,
      config: `/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './src/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    extend: {
      colors: ${JSON.stringify(colorScales, null, 2)},
      fontSize: ${JSON.stringify(typeConfig, null, 2)},
      fontFamily: {
        sans: ${fontConfig},
      },
    },
  },
  plugins: [],
}`,
      theme: {
        extend: {
          fontSize: typeConfig
        }
      }
    };
  };

  const handleColorsChange = (newColors) => {
    if (!isReadOnly) {
      setColors(newColors);
      setHasUnsavedChanges(true);
    }
  };

  const handleSaveConfig = async () => {
    try {
      const config = {
        colors,
        typeScale,
        fontFamily,
        customFont,
        h1Size,
        leadingValues
      };

      let result;

      if (configId) {
        result = await updateConfiguration(configId, config);
      } else {
        result = await saveConfiguration(config);
        setConfigId(result.id);
      }

      const newShareUrl = `${window.location.origin}/config/${result.id}`;
      setShareUrl(newShareUrl);
      setHasUnsavedChanges(false);
    } catch (error) {
      console.error('Failed to save configuration:', error);
      alert('Failed to save configuration. Please try again.');
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Astral | Visual Tailwind config tool</title>
        <meta property="og:locale" content="en" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Astral | Visual Tailwind config tool" />
        <meta property="og:description" content="Generate a minimal Tailwind setup with your brand" />
        <meta property="og:url" content="https://getastral.app" />
        <meta property="og:image" content="https://getastral.app/og-image.png" />
        <meta property="og:logo" content="https://getastral.app/logo.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Astral | Visual Tailwind config tool" />
        <meta name="twitter:description" content="Generate a minimal Tailwind setup with your brand" />
        <meta name="twitter:image" content="https://getastral.app/og-image.png" />
      </Helmet>

      <div className="swatchset flex flex-col">
        <ColorSwatch colors={colors} setColors={handleColorsChange} isReadOnly={isReadOnly} />

        <div className="flex flex-col-reverse md:flex-row gap-3 mt-3">
          
          <div className="w-full md:w-2/3 bg-[hsl(var(--surface))] rounded-xl">
            <div className="p-4 border-white/10">
              <div className="flex gap-1 w-full">
                <button
                  onClick={() => setActiveTab('colors')}
                  className={`px-4 py-1 rounded-full transition-colors ${activeTab === 'colors' ? 'bg-black/20 dark:bg-white/20' : 'hover:bg-black/20'}`}
                >
                  Colors
                </button>
                <button
                  onClick={() => setActiveTab('type')}
                  className={`px-4 py-1 rounded-full transition-colors ${activeTab === 'type' ? 'bg-black/20 dark:bg-white/20' : 'hover:bg-black/20'}`}
                >
                  Type
                </button>
              </div>
            </div>
            <Preview
              colors={colors}
              fontFamily={customFont || fontFamily}
              typeConfig={generateConfig().theme.extend.fontSize}
              activeTab={activeTab}
              leadingValues={leadingValues}
              setLeadingValues={setLeadingValues}
            />
          </div>
          
          <div className="w-full md:w-1/3 relative flax flex-col gap-3">
            <Configurator
              typeScale={typeScale}
              setTypeScale={(scale) => {
                if (!isReadOnly) {
                  setTypeScale(scale);
                  setHasUnsavedChanges(true);
                }
              }}
              fontFamily={fontFamily}
              setFontFamily={(font) => {
                if (!isReadOnly) {
                  setFontFamily(font);
                  setHasUnsavedChanges(true);
                }
              }}
              customFont={customFont}
              setCustomFont={(font) => {
                if (!isReadOnly) {
                  setCustomFont(font);
                  setHasUnsavedChanges(true);
                }
              }}
              h1Size={h1Size}
              setH1Size={(size) => {
                if (!isReadOnly) {
                  setH1Size(size);
                  setHasUnsavedChanges(true);
                }
              }}
              leadingValues={leadingValues}
              setLeadingValues={(values) => {
                if (!isReadOnly) {
                  setLeadingValues(values);
                  setHasUnsavedChanges(true);
                }
              }}
              configVisible={configVisible}
              setConfigVisible={setConfigVisible}
              generatedConfig={generateConfig()}
              onChange={handleConfigChange}
              onSave={handleSaveConfig}
              isSaving={isSaving}
              isReadOnly={isReadOnly}
              shareUrl={shareUrl}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;