import React, { useRef, useState, useCallback, useEffect } from 'react';
import { RefreshCw, Plus, Bookmark } from 'lucide-react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { generateRandomColor, getColorName, isValidHex } from '../utils';
import SwatchCard from '../ui/SwatchCard';
import FavoritesPanel from './FavoritesPanel';
import ColorDetailEditor from '../ui/ColorDetailEditor';

const ColorSwatch = ({
  colors = [],
  setColors,
  isReadOnly = false
}) => {
  const [activeColor, setActiveColor] = useState(null);
  const [previewState, setPreviewState] = useState(null);
  const [showFavorites, setShowFavorites] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const popupRef = useRef(null);
  const colorInputRef = useRef(null);

  const regenerateColors = useCallback(() => {
    setIsRegenerating(true);
    const newColors = Array.from({ length: 6 }, () => {
      const newColor = generateRandomColor();
      return {
        ...newColor,
        name: getColorName(newColor.hex)
      };
    });

    setColors(newColors);
    setTimeout(() => setIsRegenerating(false), 500);
  }, [setColors]);

  const toggleFavorite = useCallback((color) => {
    const newFavorites = favorites.some(f => f.hex === color.hex)
      ? favorites.filter(f => f.hex !== color.hex)
      : [...favorites, color];

    setFavorites(newFavorites);
    localStorage.setItem('favoriteColors', JSON.stringify(newFavorites));
  }, [favorites]);

  const addColor = useCallback(() => {
    const newColor = { hex: '000000', name: '' };
    const newColors = [...colors, newColor];
    setColors(newColors);
    setActiveColor(colors.length);
    setPreviewState(newColor);
  }, [colors, setColors]);

  const handleSaveColor = useCallback((index) => {
    if (previewState?.hex && isValidHex(previewState.hex)) {
      const newColors = [...colors];
      newColors[index] = { ...previewState };
      setColors(newColors);
      setActiveColor(null);
      setPreviewState(null);
    } else {
      alert("Please enter a valid hex code (e.g., ABC or ABCDEF).");
    }
  }, [colors, previewState, setColors]);

  const removeColor = useCallback((index) => {
    if (window.confirm("Are you sure you want to delete this color swatch?")) {
      const newColors = colors.filter((_, i) => i !== index);
      setColors(newColors);
      setActiveColor(null);
      setPreviewState(null);
    }
  }, [colors, setColors]);

  const selectFavoriteColor = useCallback((favorite) => {
    const baseHue = parseInt(favorite.hex.substring(0, 2), 16);
    const newColors = colors.map((color, index) => {
      if (index === 0) return { ...favorite };

      const offset = (index * 60) % 360;
      const newHue = (baseHue + offset) % 256;
      const newHex = newHue.toString(16).padStart(2, '0') + favorite.hex.substring(2);

      return {
        hex: newHex,
        name: getColorName(newHex),
      };
    });

    setColors(newColors);
    setShowFavorites(false);
  }, [colors, setColors]);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;

    const items = Array.from(colors);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setColors(items);
  }, [colors, setColors]);

  useEffect(() => {
    const storedFavorites = localStorage.getItem('favoriteColors');
    if (storedFavorites) {
      setFavorites(JSON.parse(storedFavorites));
    }
  }, []);

  useEffect(() => {
    if (activeColor !== null && colorInputRef.current) {
      colorInputRef.current.focus();
      colorInputRef.current.setSelectionRange(0, colorInputRef.current.value.length);
    }
  }, [activeColor]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        if (activeColor !== null && previewState && isValidHex(previewState.hex)) {
          const newColors = [...colors];
          newColors[activeColor] = { ...previewState };
        }
        setActiveColor(null);
        setPreviewState(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [activeColor, colors, previewState, setColors]);

  useEffect(() => {
    const handleKeyboard = (event) => {
      if (event.key === 'Escape' && activeColor === colors.length - 1) {
        setColors(colors.slice(0, -1));
        setActiveColor(null);
        setPreviewState(null);
      }

      if (event.code === 'Space' && !activeColor && event.target === document.body) {
        event.preventDefault();
        regenerateColors();
      }
    };

    window.addEventListener('keydown', handleKeyboard);
    return () => window.removeEventListener('keydown', handleKeyboard);
  }, [activeColor, colors, setColors, regenerateColors]);

  return (
    <section>
      {!isReadOnly && (
        <nav className="flex flex-row justify-end">
          <div className="flex flex-row">
            <button onClick={addColor} className="flex justify-center items-center py-3 px-4 text-sm gap-3 dark:hover:bg-slate-700 hover:bg-gray-100 transition-all duration-300">
              <Plus size={16} />Add Color
            </button>
            <button onClick={regenerateColors} className="flex justify-center items-center py-3 px-4 text-sm gap-3 dark:hover:bg-slate-700 hover:bg-gray-100 transition-all duration-300">
              <RefreshCw size={16} className={isRegenerating ? 'animate-spin' : ''} />
              Regenerate
            </button>
            <button onClick={() => setShowFavorites(!showFavorites)} className="flex justify-center items-center py-3 px-4 text-sm gap-3 dark:hover:bg-slate-700 hover:bg-gray-100 transition-all duration-300">
              <Bookmark size={16} />Favorites
            </button>
          </div>
        </nav>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="colors" direction="horizontal">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="flex flex-wrap w-full gap-1"
              style={{
                alignItems: 'flex-start'
              }}
            >
              {colors.map((color, index) => (
                <Draggable
                  key={`${color.hex}-${index}`}
                  draggableId={`${color.hex}-${index}`}
                  index={index}
                  isDragDisabled={isReadOnly}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`
                        relative flex-grow-0 flex-shrink-0 w-[calc((100%-5*0.25rem)/6)]
                        ${snapshot.isDragging ? 'z-50 shadow-xl' : 'hover:z-10'}
                      `}
                      style={{
                        ...provided.draggableProps.style,
                        transition: snapshot.isDragging
                          ? provided.draggableProps.style?.transition
                          : 'transform 0.2s ease'
                      }}
                    >
                      <SwatchCard
                        color={color}
                        index={index}
                        isActive={activeColor === index}
                        previewState={previewState}
                        onClick={() => {
                          if (activeColor === index) {
                            setActiveColor(null);
                            setPreviewState(null);
                          } else {
                            setActiveColor(index);
                            setPreviewState({ ...color });
                          }
                        }}
                        isReadOnly={isReadOnly}
                        onToggleFavorite={toggleFavorite}
                        isFavorite={favorites.some(f => f.hex === color.hex)}
                      >
                        {activeColor === index && previewState && (
                          <div ref={popupRef}>
                            <ColorDetailEditor
                              previewState={previewState}
                              colorInputRef={colorInputRef}
                              handleColorPickerChange={(e) => {
                                const hex = e.target.value.slice(1);
                                setPreviewState(prev => ({
                                  ...prev,
                                  hex,
                                  name: getColorName(hex)
                                }));
                              }}
                              setPreviewState={setPreviewState}
                              index={index}
                              onSave={() => handleSaveColor(index)}
                              onRemove={() => removeColor(index)}
                              isReadOnly={isReadOnly}
                            />
                          </div>
                        )}
                      </SwatchCard>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {showFavorites && (
        <FavoritesPanel
          onClose={() => setShowFavorites(false)}
          onSelect={selectFavoriteColor}
          favorites={favorites}
          onRemoveFavorite={(hex) => toggleFavorite({ hex })}
        />
      )}
    </section>
  );
};

export default ColorSwatch;